import * as React from "react";
import styled from "styled-components";
import Typography from "./typography";

interface IProps {
    sectionHeader?: string;
}

const StyledSection = styled.div`
    padding: 10px;
`;

const StyledSectionHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    color: #cc3333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
`;

const Section: React.FC<IProps> = ({ sectionHeader, children }) => {
    return (
        <StyledSection>
            {sectionHeader === undefined ? (
                undefined
            ) : (
                <StyledSectionHeader>
                    <Typography variant="h2">{sectionHeader}</Typography>
                </StyledSectionHeader>
            )}
            {children}
        </StyledSection>
    );
};

export default Section;
