import React, { useEffect, useState } from "react";
import Container from "./components/container";
import SoundGrid from "./components/soundGrid/soundGrid";
import Section from "./components/section";
import Header from "./components/header";
import Footer from "./components/footer";
import useSoundsService from "./services/soundsService";
import AnalyticsContext from "./contexts/analyticsContext";
import * as firebase from "firebase/app";
import "firebase/analytics";
import Uploader from "./components/uploader";

firebase.initializeApp({
    apiKey: "AIzaSyBVxxVfpTgb8d-vJvOveyhT4ci6qmECGpA",
    authDomain: "be-reasonable.firebaseapp.com",
    databaseURL: "https://be-reasonable.firebaseio.com",
    projectId: "be-reasonable",
    storageBucket: "be-reasonable.appspot.com",
    messagingSenderId: "462050305118",
    appId: "1:462050305118:web:7f1f5f146adf7d157080cf",
    measurementId: "G-1DSCKHT0JR",
});

const KEY_UP = "ArrowUp";
const KEY_DOWN = "ArrowDown";
const KEY_LEFT = "ArrowLeft";
const KEY_RIGHT = "ArrowRight";
const KEY_A = "KeyA";
const KEY_B = "KeyB";

const initialKonamiState = [
    KEY_UP,
    KEY_UP,
    KEY_DOWN,
    KEY_DOWN,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_B,
    KEY_A,
];

const App: React.FC = () => {
    const soundsService = useSoundsService();
    const [remKonCodes, setRemKonCodes] = useState(initialKonamiState);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (remKonCodes.length === 0) return;

            if (e.code === remKonCodes[0]) {
                const copRemKonCodes = [...remKonCodes];
                copRemKonCodes.shift();
                setRemKonCodes(copRemKonCodes);
            } else {
                setRemKonCodes(initialKonamiState);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [remKonCodes]);

    return (
        <AnalyticsContext.Provider value={{ analytics: firebase.analytics() }}>
            <Container>
                <Section>
                    <Header />
                </Section>

                {soundsService.status === "loading" && (
                    <Section sectionHeader="Loading" />
                )}

                {soundsService.status === "loaded" && (
                    <Section>
                        <SoundGrid sounds={soundsService.payload.results} />
                    </Section>
                )}

                {soundsService.status === "error" && (
                    <Section sectionHeader="Error" />
                )}

                <Section>
                    <Footer />
                </Section>

                {remKonCodes.length === 0 && (
                    <Section>
                        <Uploader />
                    </Section>
                )}
            </Container>
        </AnalyticsContext.Provider>
    );
};

export default App;
