import * as React from "react";

interface IProps {
    variant: "h1" | "h2" | "h3" | "subtitle" | "p";
}

const Typography: React.FC<IProps> = ({ variant, children }) => {
    let fontSize: number;
    let fontFamily: string;

    switch (variant) {
        case "h1":
            fontSize = 30;
            break;
        case "h2":
            fontSize = 25;
            break;
        case "h3":
            fontSize = 20;
            break;
        case "subtitle":
            fontSize = 18;
            break;
        case "p":
        default:
            fontSize = 12;
            break;
    }

    switch (variant) {
        case "h1":
        case "h2":
        case "h3":
            fontFamily = "'Francois One', sans-serif";
            break;
        case "subtitle":
        case "p":
        default:
            fontFamily = "'Rubik', sans-serif";
            break;
    }

    return (
        <div
            style={{
                fontSize: fontSize,
                fontFamily: fontFamily
            }}
        >
            {children}
        </div>
    );
};

export default Typography;
