import * as React from "react";
import styled from "styled-components";
import Typography from "./typography";

const StyledFooter = styled.div`
    padding: 30px 10px;
    color: #ffffff;
    background-color: #444444;
`;

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <Typography variant="p">
                Copyright © {new Date().getFullYear()} Be-Reasonable.com
            </Typography>
        </StyledFooter>
    );
};

export default Footer;
