import React from "react";

interface IAnalyticsContextProps {
    analytics: firebase.analytics.Analytics;
}

const AnalyticsContext = React.createContext<Partial<IAnalyticsContextProps>>(
    {}
);

export default AnalyticsContext;
