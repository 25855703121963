import * as React from "react";
import styled from "styled-components";
import { useState } from "react";
import Typography from "../typography";
import AnalyticsContext from "../../contexts/analyticsContext";

interface IProps {
    name: string;
    url: string;
}

const StyledSoundButton = styled.button`
    padding: 20px 10px;
    border: none;
    outline: none;
    color: #ffffff;
    cursor: pointer;
`;

const SoundButton: React.FC<IProps> = ({ name, url }) => {
    const analytics = React.useContext(AnalyticsContext).analytics;
    const [isPlaying, setPlayerState] = useState(false);

    const player = new Audio(url);
    player.addEventListener("ended", () => setPlayerState(false));

    const playSound = () => {
        analytics?.logEvent("button_pressed", { name: name });
        player.play();
        setPlayerState(true);
    };

    let backgroundColor: string;
    if (isPlaying) {
        backgroundColor = "#339933";
    } else {
        backgroundColor = "#cc3333";
    }

    return (
        <StyledSoundButton
            onClick={playSound}
            style={{
                backgroundColor: backgroundColor
            }}
        >
            <Typography variant="h3">{name}</Typography>
        </StyledSoundButton>
    );
};

export default SoundButton;
