import * as React from "react";
import styled from "styled-components";
import SoundButton from "./soundButton";
import { Sound } from "../../types/sound";

interface IProps {
    sounds: Sound[];
}

const StyledSoundGrid = styled.div`
    display: grid;
    grid-gap: 10px;

    grid-template-columns: repeat(1, 1fr);

    @media only screen and (min-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (min-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (min-width: 800px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const SoundGrid: React.FC<IProps> = ({ sounds }) => {
    return (
        <StyledSoundGrid>
            {sounds
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map(sound => (
                    <SoundButton
                        key={sound.id}
                        name={sound.name}
                        url={sound.url}
                    />
                ))}
        </StyledSoundGrid>
    );
};

export default SoundGrid;
