import * as React from "react";
import styled from "styled-components";
import Typography from "./typography";
import * as firebase from "firebase/app";
import "firebase/storage";
import { useState } from "react";

const StyledUploader = styled.div`
    padding: 30px 10px;
    color: #ffffff;
    background-color: #444444;
    display: flex;
    flex-direction: column;
`;

const Uploader: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<undefined | any>(
        undefined
    );

    const [outMessage, setOutMessage] = useState<undefined | string>(undefined);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;
        setSelectedFile(files[0]);
    };

    const upload = () => {
        if (!selectedFile) return;
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`uploads/${selectedFile.name}`);
        fileRef
            .put(selectedFile)
            .then(() => setOutMessage("Successfully uploaded"))
            .catch((err) => setOutMessage(err.message));
    };

    return (
        <StyledUploader>
            <Typography variant="h3">Uploader</Typography>
            {selectedFile && (
                <Typography variant="p">{selectedFile.name}</Typography>
            )}

            <input type="file" onChange={handleFileChange} />
            <button onClick={upload}>Upload</button>

            {outMessage && <Typography variant="p">{outMessage}</Typography>}
        </StyledUploader>
    );
};

export default Uploader;
// allow read, write: if request.auth != null
