import { useEffect, useState } from "react";
import { Service } from "../types/service";
import { Sound } from "../types/sound";

export interface Sounds {
    results: Sound[];
}

const useSoundsService = () => {
    const [result, setResult] = useState<Service<Sounds>>({
        status: "loading"
    });

    useEffect(() => {
        fetch("https://europe-west2-be-reasonable.cloudfunctions.net/sounds")
            .then(response => response.json())
            .then(response => {
                setResult({ status: "loaded", payload: response });
            })
            .catch(error => {
                setResult({ status: "error", error });
                console.error(error);
            });
    }, []);

    return result;
};

export default useSoundsService;
