import * as React from "react";
import styled from "styled-components";
import Typography from "./typography";

const StyledHeader = styled.div`
    padding: 30px 10px;
    color: #ffffff;
    background-color: #cc3333;
    border-top: 10px #ffffff solid;
    border-bottom: 10px #ffffff solid;
`;

const Header: React.FC = () => {
    return (
        <StyledHeader>
            <Typography variant="h1">BE-REASONABLE.com</Typography>
        </StyledHeader>
    );
};

export default Header;
